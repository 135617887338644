import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import CampaignScreen from "./screens/CampaignScreen";
import ProofScreen from "./screens/ProofScreen";
import ResultScreen from "./screens/ResultScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [currentScreen, setCurrentScreen] = useState("campaign");
  const [isProofModalOpen, setIsProofModalOpen] = useState(false);
  const [campaignProgress, setCampaignProgress] = useState({
    netflix: false,
    prime: false,
    swiggy: false,
  });

  useEffect(() => {
    // Check if userId exists in localStorage, if not, generate and store one
    let userId = localStorage.getItem("userId");
    console.log({ userId });
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem("userId", userId);
    }

    const contactInfo = localStorage.getItem("contactInfo");
    if (contactInfo) {
      setCurrentScreen("result");
    }
  }, []);

  const handleEnterCampaign = () => {
    // check if user is already verified for netflix and prime
    // if yes, then show result screen
    // else show proof screen
    const isVerified = localStorage.getItem("campaignProgress");
    // it is object netflix and prime
    const values = JSON.parse(isVerified);

    if (
      isVerified &&
      Object.values(values).every((value) => value === "success") &&
      Object.keys(values).length === 2
    ) {
      setCurrentScreen("result");
    } else {
      setIsProofModalOpen(true);
    }
  };

  const handleProofComplete = (service) => {
    setCampaignProgress((prev) => {
      const newProgress = { ...prev, [service]: true };
      if (Object.values(newProgress).every((value) => value === "success")) {
        setIsProofModalOpen(false);
        setCurrentScreen("result");
      }
      return newProgress;
    });
  };

  const handleCloseProofModal = () => {
    setIsProofModalOpen(false);
  };

  return (
    <div className="h-screen w-screen bg-white">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {currentScreen === "campaign" && (
        <CampaignScreen onEnterCampaign={handleEnterCampaign} />
      )}
      {isProofModalOpen && (
        <ProofScreen
          campaignProgress={campaignProgress}
          onProofComplete={handleProofComplete}
          onClose={handleCloseProofModal}
          screen={currentScreen}
          setCurrentScreen={setCurrentScreen}
        />
      )}
      {currentScreen === "result" && <ResultScreen />}
    </div>
  );
}

export default App;
