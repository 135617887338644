import React, { useState, useEffect } from "react";
import {
  FaExternalLinkAlt,
  FaTimes,
  FaSpinner,
  FaUtensils,
  FaShoppingBag 
} from "react-icons/fa";
import { SiPrime } from "react-icons/si";
import { RiNetflixFill } from "react-icons/ri";
import { Reclaim } from "@reclaimprotocol/js-sdk";
import { motion, AnimatePresence } from "framer-motion";
import QRCode from "react-qr-code";

function ProofScreen({ onProofComplete, onClose, screen, setCurrentScreen }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [requestUrl, setRequestUrl] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState({});
  // const steps = ["netflix", "prime", "swiggy"];
  // const steps = ["prime", "swiggy"];
  // const steps = ["swiggy"];
  const steps = ["zepto"]

  const refId = window.location.search.split("refId=")[1]?.toLowerCase();
  console.log({ refId });

  useEffect(() => {
    const savedProgress = JSON.parse(
      localStorage.getItem("campaignProgress") || "{}"
    );
    setVerificationStatus(savedProgress);
    const nextUnverifiedStep = steps.findIndex(
      (step) =>
        !savedProgress[step] ||
        savedProgress[step] === "pending" ||
        savedProgress[step] === "failed"
    );
    setCurrentStep(
      nextUnverifiedStep === -1 ? steps.length : nextUnverifiedStep
    );
    console.log(nextUnverifiedStep);
    console.log(steps.length);
  }, []);

  const getStatusIcon = (status) => {
    switch (status) {
      case "pending":
        return <FaSpinner className="animate-spin text-yellow-300" />;
      case "success":
        return "✓";
      case "failed":
        return "✗";
      default:
        return null;
    }
  };

  const getVerificationReq = async (type) => {
    setIsLoading(true);
    const APP_ID = "0x3BDC53De7451Be01A865E3768200eF55a7368226";
    const APP_SECRET =
      "0x240992a166fbef61999408465dc4e8893dde22d9ae1cf360ab950c993a01d3aa";

    const providers = [
      { id: "5267d6a2-7417-4aca-9a87-68450dbf52b0", name: "prime" },
      { id: "898e3393-5c7e-4ba9-aa9f-4ba409900dfb", name: "netflix" },
      { id: "52115a09-f081-42ce-9165-15c86122120e", name: "swiggy" },
      { id: "89d5c375-8a89-46a2-8b9d-4ac037df7c66", name: "zepto" }
    ];
    console.log(type);

    const reclaimClient = new Reclaim.ProofRequest(APP_ID);
    const providerId = providers.find((provider) => provider.name === type).id;

    const sessionData = await reclaimClient.buildProofRequest(providerId, false, "V2Linking");
    const sessionID = sessionData.sessionId;
    // reclaimClient.setRedirectUrl(window.location.href);
    reclaimClient.setSignature(
      await reclaimClient.generateSignature(APP_SECRET)
    );

    try {
      const { requestUrl } = await reclaimClient.createVerificationRequest();

      setRequestUrl(requestUrl);
      setIsLoading(false);
      updateVerificationStatus(steps[currentStep], "pending");

      await reclaimClient.startSession({
        onSuccessCallback: async (proof) => {
          console.log("Verification success", proof);
          if (!proof && !proof.length > 0) {
            console.log("Verification failed");
          } else {
            await handleProofSuccess(proof, sessionID, steps[currentStep]);
            await updateAPI();
            if (refId && refId.length > 0) {
              await updateProofGeneratedCountAPI();
            }
            /*
          if (currentStep + 1 < steps.length) {
            console.log('moving to next step');
            moveToNextStep();
          } else {
            // Both tasks are completed
            console.log('closing');
            onClose();
          } 
            */
          }
        },
        onFailureCallback: (error) => {
          console.error("Verification failed", error);
          updateVerificationStatus(steps[currentStep], "failed");
          setIsLoading(false);
        },
      });
    } catch (error) {
      console.error("Error generating request URL:", error);
      updateVerificationStatus(steps[currentStep], "failed");
      setIsLoading(false);
    }
  };

  const handleProofSuccess = async (proof, sessionID, isAmazon) => {
    if (proof && proof.length > 0) {
      const currentService = steps[currentStep];
      console.log(currentService, "success");
      await updateVerificationStatus(currentService, "success");
      const claimData = proof[0].claimData;
      const parameters = JSON.parse(claimData.parameters);
      const context = JSON.parse(claimData.context);
      if (currentService === "netflix") {
        handleNetflixProof(parameters);
        await updateAPI();
      } else if (currentService === "prime") {
        handleAmazonProof(context, sessionID);
        await updateAPI();
      } else if (currentService === "swiggy") {
        handleSwiggyProof(context, sessionID);
        await updateAPI();
      }
      else if(currentService === "zepto"){
        handleZeptoProof(context, sessionID);
        await updateAPI();
      }

      //if its last one
      if (currentService === "zepto") {
        setCurrentScreen("result");
      }

      if (currentStep + 1 < steps.length && currentService !== "swiggy") {
        console.log("moving to next step");
        moveToNextStep();
      }
    }
  };

  const handleAmazonProof = (context, sessionID) => {
    console.log("Handling Amazon Prime proof");
    let extractedData = JSON.parse(localStorage.getItem("extractedData")) || {};

    if (
      context.extractedParameters &&
      context.extractedParameters.isPrimeCustomer !== undefined
    ) {
      const isPrimeCustomer = context.extractedParameters.isPrimeCustomer;
      console.log("Is Prime Customer:", isPrimeCustomer);

      if (isPrimeCustomer) {
        extractedData.hasAmazonPrime = true;
      } else {
        extractedData.hasAmazonPrime = false;
      }
    } else {
      console.log("Unable to determine Prime status");
      extractedData.hasAmazonPrime = false;
    }
    extractedData.amazonPrimeSessionId = sessionID
    localStorage.setItem("extractedData", JSON.stringify(extractedData));
  };

  const handleNetflixProof = (parameters) => {
    let extractedData = JSON.parse(localStorage.getItem("extractedData")) || {};

    if (parameters.paramValues && parameters.paramValues.currentPlan) {
      try {
        const currentPlan = JSON.parse(parameters.paramValues.currentPlan);
        console.log("Extracted plan:", currentPlan);

        if (currentPlan === null) {
          console.log("User does not have a Netflix plan");
          extractedData.hasNetflix = false;
        } else if (currentPlan.plan && currentPlan.plan.name) {
          const planName = currentPlan.plan.name.toLowerCase();
          if (["mobile", "basic", "standard", "premium"].includes(planName)) {
            console.log("Customer has a valid Netflix plan:", planName);
            extractedData.hasNetflix = true;
            extractedData.netflixPlan = planName;
          } else {
            console.log("Customer has a non-premium Netflix plan:", planName);
            extractedData.hasNetflix = false;
          }
        } else {
          console.log("Invalid plan structure");
          extractedData.hasNetflix = false;
        }
      } catch (error) {
        console.error("Error parsing currentPlan:", error);
        extractedData.hasNetflix = false;
      }
    } else {
      console.log("No plan information provided");
      extractedData.hasNetflix = false;
    }

    localStorage.setItem("extractedData", JSON.stringify(extractedData));
  };

  const handleSwiggyProof = (context, sessionID) => {
    console.log("Handling Swiggy proof");
    let extractedData = JSON.parse(localStorage.getItem("extractedData")) || {};
    const isSwiggyCustomer = context.extractedParameters.user_status;

    if (isSwiggyCustomer === "NOT_SUPER") {
      extractedData.hasSwiggyOne = false;
    } else {
      extractedData.hasSwiggyOne = true;
      extractedData.swiggyPlan = isSwiggyCustomer;
    }
    extractedData.swiggySessionId = sessionID
    localStorage.setItem("extractedData", JSON.stringify(extractedData));
  };

  const handleZeptoProof = (context, sessionID) => {
    console.log("Handling Zepto proof");
    let extractedData = JSON.parse(localStorage.getItem("extractedData")) || {};
    const isZeptoPassCustomer = context.extractedParameters.totalSavingsTitle;

    if (isZeptoPassCustomer === "Congratulations! You've upgraded to Pass membership") {
      extractedData.hasZeptoPass = true;
    } else {
      extractedData.hasZeptoPass = false;
    }
    extractedData.zeptoSessionId = sessionID
    localStorage.setItem("extractedData", JSON.stringify(extractedData));
  };

  const updateAPI = async () => {
    try {
      const extractedData =
        JSON.parse(localStorage.getItem("extractedData")) || {};
      const response = await fetch(
        "https://backend.proofofliving.in/api/update-2",
        // "http://localhost:3000/api/update-2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: localStorage.getItem("userId"),
            ...extractedData,
            campaignSource: window.location.origin
          }),
        }
      );
      console.log({ response });
    } catch (error) {
      console.log("Error updating API:", error);
      console.error("Error updating API:", error);
    }
  };

  const updateProofGeneratedCountAPI = async () => {
    console.log("Updating proof generated count API", { refId });
    try {
      const response = await fetch(
        "https://backend.proofofliving.in/api/trackProofGenerated2",
        // "http://localhost:3000/api/trackProofGenerated-2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refId: refId,
          }),
        }
      );
      console.log({ response });
    } catch (error) {
      console.error("Error tracking proof generated count:", error);
    }
  };

  const updateVerificationStatus = (service, status) => {
    setVerificationStatus((prev) => {
      const newStatus = { ...prev, [service]: status };
      console.log(newStatus);
      localStorage.setItem("campaignProgress", JSON.stringify(newStatus));
      return newStatus;
    });
  };

  const moveToNextStep = () => {
    setCurrentStep((prev) => {
      const nextStep = prev + 1;
      setRequestUrl(null);
      setIsLoading(false);
      return nextStep;
    });
  };

  const handleProof = () => {
    getVerificationReq(steps[currentStep]);
  };

  const handleOpenLink = () => {
    if (requestUrl) {
      window.open(requestUrl, "_blank");
    }
  };

  const getIcon = (step) => {
    switch (step) {
      case "netflix":
        return <RiNetflixFill className="text-red-600 text-4xl" />;
      case "prime":
        return <SiPrime className="text-yellow-500 text-4xl" />;
      case "swiggy":
        return <FaUtensils className="text-green-600 text-4xl" />;
      case "zepto":
        return <FaShoppingBag className="text-blue-500 text-4xl" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "text-yellow-300";
      case "success":
        return "text-green-400";
      case "failed":
        return "text-red-400";
      default:
        return "text-gray-400";
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        transition={{ type: "spring", damping: 30, stiffness: 300 }}
        className="fixed inset-x-0 bottom-0 h-4/5 md:inset-0 md:h-full 
        md:bg-gradient-to-b md:from-gray-900 md:to-black
     md:flex md:items-center md:justify-center"
      >
        <div
          className="h-full md:h-auto md:w-2/3 lg:w-1/2 xl:w-1/3 flex flex-col p-6 space-y-6 bg-gray-800
        rounded-t-3xl
        md:rounded-3xl md:shadow-2xl"
        >
          <motion.div
            className="flex justify-between items-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h2 className="md:text-3xl text-xl font-bold text-white">
              Verify Your Account
            </h2>
            <button
              onClick={onClose}
              className="text-white hover:text-gray-300 transition-colors"
            >
              <FaTimes size={24} />
            </button>
          </motion.div>

          <div className="flex-grow flex flex-col justify-center items-center space-y-8">
            <motion.div
              className="w-24 h-24 bg-white rounded-full flex items-center justify-center shadow-lg"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
            >
              {getIcon(steps[currentStep])}
            </motion.div>
            <motion.h3
              className="text-2xl font-bold text-center text-white"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              Step {currentStep + 1}: Verify Your{" "}
              {steps[currentStep].charAt(0).toUpperCase() +
                steps[currentStep].slice(1)}
            </motion.h3>

            <motion.div
              className="flex items-center space-x-2 font-semibold"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <span
                className={getStatusColor(
                  verificationStatus[steps[currentStep]]
                )}
              >
                Status:{" "}
                {verificationStatus[steps[currentStep]] || "Not started"}
              </span>
              {getStatusIcon(verificationStatus[steps[currentStep]])}
            </motion.div>

            <motion.div
              className="w-full space-y-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              {requestUrl ? (
                <div className="flex flex-col items-center space-y-4">
                  <div className="hidden md:block p-4 bg-white rounded-lg shadow-md ">
                    <QRCode value={requestUrl} size={200} />
                  </div>
                  <p
                    className="text-center text-white
                    bg-white/10 p-4 rounded-lg

                   hidden md:block
                  "
                  >
                    Scan the QR code using your mobile
                  </p>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleOpenLink}
                    className="w-full
                    md:hidden block
                    md:w-auto bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-4 px-6 rounded-full transition duration-300 ease-in-out flex items-center justify-center"
                  >
                    <span>Open Verification Link</span>
                    <FaExternalLinkAlt className="ml-2" />
                  </motion.button>
                </div>
              ) : (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleProof}
                  disabled={isLoading}
                  className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white font-bold py-4 px-6 rounded-full transition duration-300 ease-in-out disabled:opacity-50 flex items-center justify-center"
                >
                  {isLoading
                    ? "Generating Link..."
                    : `Verify ${
                        steps[currentStep].charAt(0).toUpperCase() +
                        steps[currentStep].slice(1)
                      }`}
                </motion.button>
              )}
            </motion.div>
          </div>

          <motion.div
            className="flex justify-center space-x-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            {steps.map((step, index) => (
              <motion.div
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index === currentStep
                    ? "bg-white"
                    : verificationStatus[step] === "success"
                    ? "bg-green-500"
                    : verificationStatus[step] === "failed"
                    ? "bg-red-500"
                    : verificationStatus[step] === "pending"
                    ? "bg-yellow-500"
                    : "bg-gray-400"
                }`}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.1 * index }}
              />
            ))}
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default ProofScreen;
