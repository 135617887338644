import React, { useState, useEffect } from "react";
import {
  FaTrophy,
  FaEnvelope,
  FaPhone,
  FaCheck,
  FaTimes,
  FaToggleOn,
  FaToggleOff,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResultScreen() {
  const [contactType, setContactType] = useState("phone");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isEligible, setIsEligible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const updateAPI = async () => {
    try {
      const extractedData =
        JSON.parse(localStorage.getItem("extractedData")) || {};
      const response = await fetch(
        "https://backend.proofofliving.in/api/update-2",
        // "http://localhost:3001/api/update-2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: localStorage.getItem("userId"),
            ...extractedData,
            campaignSource: window.location.origin
          }),
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Error updating API:", error);
    }
  };
  useEffect(() => {
    const extractedData = JSON.parse(
      localStorage.getItem("extractedData") || "{}"
    );

    // Safely check if campaignProgress is an object and has any properties
    if (!extractedData.hasSwiggyOne) {
      setIsEligible(true);
    } else {
      setIsEligible(false);
    }

    const savedContact = localStorage.getItem("contactInfo");
    if (savedContact) {
      const { type, value } = JSON.parse(savedContact);
      setContactType(type);
      if (type === "email") {
        setEmail(value);
      } else {
        setPhone(value);
      }
      setIsSubmitted(true);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!phone?.trim().length) {
      toast.error("Please enter your phone number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    const contactInfo = contactType === "email" ? email : phone;
    localStorage.setItem(
      "contactInfo",
      JSON.stringify({ type: contactType, value: contactInfo })
    );
    const extractedData =
      JSON.parse(localStorage.getItem("extractedData")) || {};
    if (contactType === "email") {
      extractedData.email = contactInfo;
    } else {
      extractedData.phone = contactInfo;
    }
    localStorage.setItem("extractedData", JSON.stringify(extractedData));
    updateAPI();
    setIsSubmitted(true);
    console.log("Submitted contact:", contactInfo);
    toast.success(
      "Thanks for submitting your details. We'll contact you soon with more information!",
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  };

  const toggleContactType = () => {
    if (!isSubmitted) {
      setContactType((prev) => (prev === "email" ? "phone" : "email"));
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-gradient-to-b from-gray-900 to-black flex items-center justify-center px-4 relative overflow-hidden"
      >
        {/* Dynamic background effect */}
        <div className="fixed inset-0 overflow-hidden pointer-events-none">
          {/* {isEligible ? (
            // Crazy background
            <div className="absolute inset-0 animate-crazy">
              {[...Array(50)].map((_, i) => (
                <div
                  key={i}
                  className="absolute rounded-full bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 opacity-0 animate-crazy-shape"
                  style={{
                    top: `${(i % 10) * 10}%`,
                    left: `${Math.floor(i / 10) * 20}%`,
                    width: "50px",
                    height: "50px",
                    animationDelay: `${i * 0.1}s`,
                  }}
                />
              ))}
            </div>
          ) : (
            // Starry night with meteors
            <div className="absolute inset-0">
              {[...Array(100)].map((_, i) => (
                <div
                  key={i}
                  className="absolute rounded-full bg-white opacity-0 animate-twinkle"
                  style={{
                    top: `${(i % 10) * 10}%`,
                    left: `${Math.floor(i / 10) * 10}%`,
                    width: "2px",
                    height: "2px",
                    animationDelay: `${i * 0.1}s`,
                  }}
                />
              ))}
              {[...Array(5)].map((_, i) => (
                <div
                  key={`meteor-${i}`}
                  className="absolute bg-white opacity-0 animate-meteor"
                  style={{
                    top: `${i * 20}%`,
                    left: "100%",
                    width: "2px",
                    height: "2px",
                    animationDelay: `${i * 2}s`,
                  }}
                />
              ))}
            </div>
          )} */}
          <div className="absolute inset-0 animate-crazy">
            {[...Array(50)].map((_, i) => (
              <div
                key={i}
                className="absolute rounded-full bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 opacity-0 animate-crazy-shape"
                style={{
                  top: `${(i % 10) * 10}%`,
                  left: `${Math.floor(i / 10) * 20}%`,
                  width: "50px",
                  height: "50px",
                  animationDelay: `${i * 0.1}s`,
                }}
              />
            ))}
          </div>
        </div>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
          className="bg-white bg-opacity-10 rounded-3xl p-8 shadow-2xl backdrop-blur-md space-y-8 w-full max-w-md relative z-10"
        >
          <motion.div
            className="flex justify-center"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.2,
            }}
          >
            {/* {isEligible ? (
              <div className="w-24 h-24 bg-green-500 rounded-full flex items-center justify-center animate-pulse">
                <FaCheck className="text-white text-5xl" />
              </div>
            ) : (
              <div className="w-24 h-24 bg-red-500 rounded-full flex items-center justify-center">
                <FaTimes className="text-white text-5xl" />
              </div>
            )} */}
            <div className="w-24 h-24 bg-green-500 rounded-full flex items-center justify-center animate-pulse">
              <FaCheck className="text-white text-5xl" />
            </div>
          </motion.div>
          <motion.h2
            className="text-3xl font-bold text-center text-white"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {isEligible
              ? "Congratulations! You're eligible!"
              : "We're sorry, you're not eligible."}
            {/* Congratulations! You're eligible! */}
          </motion.h2>
          <motion.p
            className="text-center text-gray-300"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            {isEligible
              ? "You've successfully completed all tasks!"
              : "Don't worry, there will be more opportunities in the future."}
            {/* You've successfully completed all tasks! */}
          </motion.p>
          <motion.div
            className="space-y-6"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            {isSubmitted ? (
              <div className="text-center text-white">
                <p className="mb-4">Your {contactType}:</p>
                <p className="font-bold">
                  {contactType === "email" ? email : phone}
                </p>
                <p className="mt-4">
                  We'll contact you soon with more information!
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="flex items-center justify-between">
                  <span className="text-white">Contact via:</span>
                  <button
                    type="button"
                    onClick={toggleContactType}
                    className="text-purple-500 flex items-center focus:outline-none"
                  >
                    {contactType === "email" ? (
                      <>
                        <FaToggleOn className="text-2xl mr-2" />
                        <span>Email</span>
                      </>
                    ) : (
                      <>
                        <FaToggleOff className="text-2xl mr-2" />
                        <span>Phone</span>
                      </>
                    )}
                  </button>
                </div>
                <div className="relative">
                  {contactType === "email" ? (
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email address"
                      required
                      className="w-full px-4 py-3 rounded-full bg-white bg-opacity-20 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 pl-12"
                    />
                  ) : (
                    <input
                      type="tel"
                      value={phone}
                      maxLength={10}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Enter your phone number"
                      required
                      className="w-full px-4 py-3 rounded-full bg-white bg-opacity-20 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 pl-12"
                    />
                  )}
                  {contactType === "email" ? (
                    <FaEnvelope className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  ) : (
                    <FaPhone className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  )}
                </div>
                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-4 px-6 rounded-full transition duration-300 ease-in-out transform hover:shadow-lg"
                >
                  {isEligible ? "Claim Your Reward" : "Stay Updated"}
                  {/* Claim Your Reward */}
                </motion.button>
              </form>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
      <ToastContainer />
    </AnimatePresence>
  );
}

export default ResultScreen;
